<template>
  <base-slider class="cms-element-bs-image-circle-slider" :class="classes">
    <base-slider-item
      v-for="(item, index) in items"
      :key="`${index}-${item.id}`"
    >
      <div class="px-3 mb-5" :style="itemStyle">
        <cms-util-link
          :url="item.url"
          wrapped="div"
          class="cms-element-bs-image-circle-slider__circle"
          :style="mediaStyle"
        >
          <base-image
            :init-width="mediaSizeNumber"
            :media="item.media"
            :alt="item.alt"
            loading="lazy"
            :width="mediaSizeNumber"
            :height="mediaSizeNumber"
          />
        </cms-util-link>
        <div
          class="
            cms-element-bs-image-circle-slider__text
            text-center text-md-h6 text-body-4
            mt-2 mt-md-3
          "
          :class="textClasses"
        >
          <div v-if="item.subtitle" class="grey-darken-1--text text-truncate">
            {{ item.subtitle }}
          </div>
          <div class="text-truncate">
            {{ item.title }}
          </div>
        </div>
      </div>
    </base-slider-item>
  </base-slider>
</template>

<script>
import { getCmsSlotConfigProperty } from '~/helpers'
import BaseSlider from '~/components/base/slider/BaseSlider'
import BaseSliderItem from '~/components/base/slider/BaseSliderItem'
import BaseImage from '~/components/base/image/BaseImage'

export default {
  name: 'CmsElementBsImageCircleSlider',
  components: {
    BaseImage,
    BaseSliderItem,
    BaseSlider,
  },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedItem: 0,
    }
  },
  computed: {
    sliderImages() {
      return this.content.data?.sliderItems || []
    },
    items() {
      return this.sliderImages.map((item) => {
        return {
          id: item.id,
          url: item.url,
          media: item.mainMedia,
          alt: item.mainMedia.alt,
          title: item.title,
          subtitle: item.subtitle,
          width: this.mediaSizeNumber,
          height: this.mediaSizeNumber,
        }
      })
    },
    textClasses() {
      return getCmsSlotConfigProperty(this.content, 'textCss')
    },
    classes() {
      return [getCmsSlotConfigProperty(this.content, 'elementCss')]
    },
    itemStyle() {
      return {
        width: `calc(${this.mediaSize || '100px'} + 24px)`,
      }
    },
    mediaSize() {
      return getCmsSlotConfigProperty(this.content, 'mediaSize') || undefined
    },
    mediaStyle() {
      return {
        height: this.mediaSize,
        width: this.mediaSize,
      }
    },
    mediaSizeNumber() {
      const width = this.mediaSize || '100'

      return parseInt(width.replace('px', '').trim())
    },
  },
}
</script>
